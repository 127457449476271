import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper';
import { Pagination, Autoplay } from 'swiper/modules';

export default class extends Controller {
  static targets = ["pagination"]

  connect() {
    console.log(this.paginationTarget)
    this.initSlider()
  }

  disconnect() {
    this.swiper.destroy()
  }

  initSlider() {
    const options = {
      modules: [Pagination, Autoplay],
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      loop: true,
      pagination: {
        el: this.paginationTarget,
      },
    }

    this.swiper = new Swiper(this.element, options)
  }
}
