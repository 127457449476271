// NOTE: this is work around for dropdowns util we found better way to handle them
// with turbolinks we need to reinit flowbite to make dropdown in the header works after redirect
// to avoid any styling issues after flowbite reinit lets hide dropdown after we click on an item

document.addEventListener('turbolinks:load', function() {
    if (window.initFlowbite) {
        window.initFlowbite();
    }

    const dropdownItems = document.querySelectorAll('#dropdownNavbar-1 li a, #dropdownNavbar-2 li a');
    dropdownItems.forEach(item => {
        item.addEventListener('click', function() {
            const dropdownId = this.closest('.dropdown-navbar').id;
            const dropdownToggle = document.querySelector(`[data-dropdown-toggle="${dropdownId}"]`);
            const dropdown = document.getElementById(dropdownId);

            // Add smooth transition
            dropdown.style.transition = 'opacity 0.3s ease-out';
            dropdown.style.opacity = '0';

            setTimeout(() => {
                dropdown.classList.add('hidden');
                dropdown.style.opacity = '1';
            }, 100);

            dropdownToggle.setAttribute('aria-expanded', 'false');
        });
    });
});