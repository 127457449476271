// Add address validation to user edit page
$(document).on('turbolinks:load', function () {
  if ($('body').is('[data-js-page-id="users#edit"]')) {
    console.log('users#edit page setup');
    AddressValidation.init();

    // On valid address
    $("[data-address-validate]").on('addressValidation.valid', function () {
      $(this).find('[type="submit"]').prop('disabled', false);

      // Hide the invalid message and remove error text
      $(this).find('.invalid-address-message')
          .addClass('hide')  // Hide the message
          .text('');  // Clear the text message

      // Highlight fields as valid
      $(this).find("[data-address]").removeClass('is-invalid').addClass('is-valid');
    });


    // On invalid address
    $("[data-address-validate]").on('addressValidation.invalid', function () {
      $(this).find('[type="submit"]').prop('disabled', true);
      $(this).find('.invalid-address-message')
          .removeClass('hide')
          .addClass('text-danger')  // Add this line to change text color to red
          .text('Invalid address. Please check the details.');
      $(this).find("[data-address]").removeClass('is-valid').addClass('is-invalid'); // Highlight fields as incomplete
    });

    // On incomplete address
    $("[data-address-validate]").on('addressValidation.incomplete', function () {
      $(this).find('[type="submit"]').prop('disabled', true);
      $(this).find('.invalid-address-message')
          .removeClass('hide')
          .removeClass('text-danger')
          .text('Please complete all address fields.'); // Display a message

    });

    // Trigger the initial validation on load
    $("[data-address-validate]").trigger('validate');
  }
});

$(document).on('turbolinks:before-cache', function () {
  if ($('body').is('[data-js-page-id="users#edit"]')) {
    console.log('users#edit page teardown');
  }
});
