// Common function to initialize forms
function initializeForms() {
    const body = document.querySelector("body");

    // Registration form initialization
    if (body.dataset.jsPageId === "registration_form") {
        console.log("Identified Registration form");
        const form = document.getElementById("register_user");
        const submitButton = document.getElementById("create-account-button");
        const requiredFields = form.querySelectorAll("input[required]");
        const marketingOptIn = document.getElementById("marketing-opt-in");

        function checkFormValidity() {
            const allFilled = Array.from(requiredFields).every(input => input.value.trim() !== "");
            const isChecked = marketingOptIn.checked;
            submitButton.disabled = !(allFilled && isChecked);
        }

        requiredFields.forEach(input => {
            input.addEventListener("input", checkFormValidity);
        });

        marketingOptIn.addEventListener("change", checkFormValidity);
        checkFormValidity();
    }

    // Sign in form initialization
    if (body.dataset.jsPageId === "sign_in_form") {
        console.log("Identified Sign In form");
        const form = document.querySelector(".login-form");
        const submitButton = form.querySelector("input[type='submit']");
        const emailField = form.querySelector("input[type='email']");
        const passwordField = form.querySelector("input[type='password']");

        function checkFormFilled() {
            const emailFilled = emailField.value.trim() !== "";
            const passwordFilled = passwordField.value.trim() !== "";
            submitButton.disabled = !(emailFilled && passwordFilled);
        }

        emailField.addEventListener("input", checkFormFilled);
        passwordField.addEventListener("input", checkFormFilled);
        checkFormFilled();
    }

    // Password update form initialization
    if (body.dataset.jsPageId === "edit_password") {
        const form = document.querySelector(".password-form");
        const submitButton = form.querySelector("input[type='submit']");
        const currentPasswordField = document.getElementById("user_current_password");
        const newPasswordField = document.getElementById("user_password");
        const confirmPasswordField = document.getElementById("user_password_confirmation");

        function checkPasswordFormValidity() {
            const currentPasswordFilled = currentPasswordField.value.trim() !== "";
            const newPasswordFilled = newPasswordField.value.trim() !== "";
            const confirmPasswordFilled = confirmPasswordField.value.trim() !== "";

            // Enable submit button when all fields are filled and passwords match
            submitButton.disabled = !(
                currentPasswordFilled &&
                newPasswordFilled &&
                confirmPasswordFilled
            );
        }

        currentPasswordField.addEventListener("input", checkPasswordFormValidity);
        newPasswordField.addEventListener("input", checkPasswordFormValidity);
        confirmPasswordField.addEventListener("input", checkPasswordFormValidity);

        // Initialize button state on load
        checkPasswordFormValidity();
    }
}

// Listen for both DOMContentLoaded and turbolinks:load
document.addEventListener("DOMContentLoaded", initializeForms);
document.addEventListener("turbolinks:load", initializeForms);