import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
window.toastr = toastr;

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": true,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "2000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

// Handle flash messages
document.addEventListener('turbolinks:load', () => {
    const flashMessages = document.querySelectorAll('[data-flash]');

    flashMessages.forEach(flash => {
        const type = flash.dataset.type;
        const message = flash.dataset.message;

        // Show notification
        toastr[type](message);

        // Remove flash message from DOM to prevent re-triggering
        flash.parentNode.removeChild(flash);
    });
});