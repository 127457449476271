import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import 'flowbite';
import 'bootstrap';

const application = Application.start()
const context = require.context("./controllers", true, /.js$/)
application.load(definitionsFromContext(context))

// load Turbolinks
import Turbolinks from "turbolinks"
Turbolinks.start()

// load flashes with toastr gem
require('./toastr');

// load jQuery
window.jQuery = require("jquery");
window.$ = require("jquery");

// load address validation modules
require('../../customer_side/Javascripts/custom_modules/address_validation');
require('../../customer_side/Javascripts/user_edit');

// load custom scripts
require('./enable_submit_button');
require('./handle_dropdowns');
require('./handle_trustpilot_widget');